import { Component, Input, OnInit, NgZone } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from '../../services/api.service';
import { DatePipe } from '@angular/common';
import { TransactionLogModel } from "../../models/transationLog.model";
import { TransactionLogService } from '../../services/transaction-log.service';
import { ControllersService } from '../../services/ionic-common/controllers.service';



@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.page.html',
  styleUrls: ['./paypal.page.scss'],
})
export class PaypalPage {
  @Input() amount;
  @Input() fishCoin;
  @Input() fishProfile;
  currency: string = 'USD'; currencyIcon: string = '$';
  approved: boolean = false;
  name: string; pltServerMargin: any = 0;
  transactionLogModel: TransactionLogModel = {};

  constructor(public navCtrl: NavController, private navParams: NavParams, public transactionLogService: TransactionLogService,
    private apiService: ApiService, private storage: Storage, private datePipe: DatePipe,
    private controllerService: ControllersService, private ngZone: NgZone, private modalCtrl: ModalController) {
    this.amount = this.navParams.get('amount');
    this.fishCoin = this.navParams.get('fishCoin');
    this.fishProfile = this.navParams.get('fishProfile');
    // console.log(this.amount);
    // console.log(this.fishProfile);
    // console.log(this.fishCoin);
    let _this = this;

    let amt = this.amount.toString();
    //console.log(amt);
    setTimeout(() => {
      // Render the PayPal button into #paypal-button-container
      <any>window['paypal'].Buttons({
        // Set up the transaction
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amt
              }
            }]
          });
        },

        // Finalize the transaction
        onApprove: function (data, actions) {
          actions.order.capture().then(function (details) {
            // console.log(JSON.stringify(details));
            _this.updateBalance(details);
          }).catch(err => {
            //  console.log(err);
          });
        }
      }).render('#paypal-button-container');
    }, 500);

  }

  updateBalance(details) {
    this.controllerService.showLoading();
    this.apiService.getCurrentUserAccotDetailsByUId(this.fishProfile.userId.toString()).then(cuser => {
      // console.log('cuser :'+JSON.stringify(cuser));
      var blockchainInputModel: any = {};
      blockchainInputModel.destAcct = cuser.PublicKey;
      blockchainInputModel.destSeed = cuser.SecretKey;
      blockchainInputModel.destUserID = cuser.UserID;
      blockchainInputModel.amount = this.fishCoin.toString();
      this.apiService.postDataOnBlockchain("transferFishAssetsFromMerchant", blockchainInputModel).subscribe((res: any) => {
        //  console.log('>> transferFishAssetsFromMerchant :' + JSON.stringify(res));
        this.transactionLogModel.srcUserID = "MerchantId";
        this.transactionLogModel.destUserID = blockchainInputModel.destUserID;
        if (res !== undefined && res.Result !== undefined) {
          this.transactionLogModel.stellarHash = res.Result[0].stellarTransactionHash;
        }
        this.transactionLogModel.paymentType = "Paypal";
        // web interface success response id
        this.transactionLogModel.paypalId = details.purchase_units[0].payments.captures[0].id;
        this.transactionLogModel.createdBy = blockchainInputModel.destUserID;
        this.transactionLogModel.paypalValue = this.amount.toString();
        this.transactionLogModel.fishcoin = this.fishCoin;
        this.transactionLogModel.createdDate = this.datePipe.transform(new Date().toString(), "yyyy-MM-dd HH:mm:ss");
        // Transfer Fishcoin On Merchant Account
        var transferModel: any = {};
        transferModel.srcAcct = cuser.PublicKey;
        transferModel.srcSeed = cuser.SecretKey;
        transferModel.srcUserID = cuser.UserID;
        transferModel.amount = ((this.pltServerMargin) * (blockchainInputModel.amount / 100)).toString();
        let msg = 'Transaction completed by ' + details.payer.name.given_name + '!';
        this.controllerService.show(msg);
        this.transactionLogService.AddTransactionLog(this.transactionLogModel).then(transLogRes => {
          //this.hideLoader();
          this.controllerService.dismiss();
          this.navCtrl.back();
          //console.log('transLogRes :' + JSON.stringify(transLogRes));
          this.storage.set('PreviousPage', "TransactionPage");
          this.controllerService.show('Fishcoin bought successfully!');
          this.modalCtrl.dismiss();
        });
      }, err => {
        //console.log('err postDataOnBlockchain', JSON.stringify(err));
        this.controllerService.dismiss();
        this.controllerService.show('Error occurred during transaction');
      });
    });
  }




  close() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
