import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { Network } from '@ionic-native/network/ngx';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirewallService {

  baseBlockchainURL: String = environment.baseBlockchainURL;
  stellarURL: String = environment.stellarURL;
  transferToURL: String = environment.transferToURL;
  transferto_apikey: String = environment.transferto_apikey;
  transferto_apisecret: String = environment.transferto_apisecret;
  userData: any;
  data: any;
  firewallUrl: String = environment.baseCMXURL;
  constructor(public network: Network
    ,public httpc: HttpClient // public toast: ToastService,
    ) {
    
    //console.log(this.firewallUrl);
  }

  getCountryList(){
    return this.httpc.get(this.firewallUrl+'/firebase/getCountryList').pipe(catchError(this.handleError));
  }

  getFishingGear(){
    return this.httpc.get(this.firewallUrl+'/firebase/getFishingGear').pipe(catchError(this.handleError));
  }

  getUserList(){
    return this.httpc.get(this.firewallUrl+'/firebase/getUserList').pipe(catchError(this.handleError));
  }

  getFOAMajorFishingArea(){
    return this.httpc.get(this.firewallUrl+'/firebase/getFOAMajorFishingArea').pipe(catchError(this.handleError));
  }

  getAllSpeciesByRole(data){
    return this.httpc.post(this.firewallUrl+'/firebase/getAllSpeciesByRole',data).pipe(catchError(this.handleError));
  }

  getDialCodeByCountry(data){
    return this.httpc.post(this.firewallUrl+'/firebase/getDialCodeByCountry',data).pipe(catchError(this.handleError));
  }

  getCreditTxnLogListByUserID(data){
    return this.httpc.post(this.firewallUrl+'/firebase/getCreditTxnLogListByUserID',data).pipe(catchError(this.handleError));
  }

  getDebitTxnLogListByUserID(data){
    return this.httpc.post(this.firewallUrl+'/firebase/getDebitTxnLogListByUserID',data).pipe(catchError(this.handleError));
  }

  userExists(data){
    return this.httpc.post(this.firewallUrl+'/login/userIsExist',data).pipe(catchError(this.handleError));
  }

  addTransactionLog(data){

  }
  //ERROR HANDLING FOR HTTP CLIENT METHODS
  private handleError (error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    //console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
