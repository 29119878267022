import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { InvitesModel } from "../models/invites.model";

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  inviteList: AngularFireList<any>;
  invitesListArray: Array<{ URole: string, UserId: string, UName:string, LoginId:string }> = [];
  invite: Array<{ PublicKey: string, SecretKey: string, UserID: string }> = [];
  constructor(public db: AngularFireDatabase) {
    this.inviteList = db.list('/InvitesLog');
    //console.log('TxnList :'+JSON.stringify(this.TxnList));
  }

  // Add Transaction Log
  addInvites(invitesLogModel: InvitesModel) {
    //console.log(transactionLogModel);
    return this.inviteList.push(invitesLogModel)
  }

  //SENT INVITES
  getInvitesByReceiverID(userID: string) {
    return this.db.list<InvitesModel>('/InvitesLog', ref => ref.orderByChild('receiverId').equalTo(userID.toString()));
  }

  //RECEIVED INVITES
  getInvitesBySenderID(userID: string) {
    return this.db.list<InvitesModel>('/InvitesLog', ref => ref.orderByChild('senderId').equalTo(userID.toString()));
  }

  getAcceptedInvitesBySenderID(userID: string) {
    return this.db.list<InvitesModel>('/InvitesLog', ref => ref.orderByChild('senderId').equalTo(userID.toString()));
  }

  //INVITE EXISTS IN SENT
  sentInviteExists(inModel:InvitesModel){
    //console.log('sentInviteExists :'+JSON.stringify(inModel));
    return this.db.list<InvitesModel>('/InvitesLog', ref=> ref.orderByChild('senderId').equalTo(inModel.senderId.toString()))
  }

  //INVITE EXISTS IN RECEIVED
  receivedInviteExists(inModel:InvitesModel){
    //console.log('receivedInviteExists :'+JSON.stringify(inModel));
    return this.db.list<InvitesModel>('/InvitesLog', ref=> ref.orderByChild('receiverId').equalTo(inModel.senderId.toString()))
  }

  //UPDATE INVITE STATUS
  updateLog(rec){
    this.inviteList.update(rec.key,rec)
  }

  //DELETE INVITE
  deleteLog(rec){
    this.inviteList.remove(rec.key);
  }
}
