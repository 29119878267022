import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ModalController,NavParams } from '@ionic/angular';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.page.html',
  styleUrls: ['./crop-image.page.scss'],
})
export class CropImagePage implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  constructor(public modalCtrl:ModalController,public navParams:NavParams) { }

  ngOnInit() {
    this.imageChangedEvent = this.navParams.get('image');
   // console.log(this.imageChangedEvent);

  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  //  console.log(event.base64);
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  confirm(){
    this.modalCtrl.dismiss(this.croppedImage);

  }

  dismiss(){
    this.modalCtrl.dismiss(null);

  }
}
